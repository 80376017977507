import { CognitoUserPool } from 'amazon-cognito-identity-js';

export enum AvailProvider {
  Qwiki = 'qwiki',
  KiaB = 'KiaB',
  Piening = 'piening',
  Eugster = 'eugster',
}

export enum GrantType {
  Authorization = 'authorization_code',
  Refresh = 'refresh_token',
  Client = 'client_credentials',
}

export const awsExports = {
  UserPoolId: 'eu-central-1_hdIKYlLvY',
  ClientId: '7or5qtbj4ag61j9jcdqrvsgj58',
  userInfoEndpoint: 'https://kiab-test.auth.eu-central-1.amazoncognito.com/oauth2/userInfo',
  authorizationEndpoint: 'https://kiab-test.auth.eu-central-1.amazoncognito.com/oauth2/authorize',
  tokenEndpoint: 'https://kiab-test.auth.eu-central-1.amazoncognito.com/oauth2/token',
  revokeTokenEndpoint: 'https://kiab-test.auth.eu-central-1.amazoncognito.com/oauth2/revoke',
};

export const baseProvider = {
  ...awsExports,
  redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
  redirectSignOut: `${process.env.REACT_APP_COGNITO_REDIRECT_URL}/logout`,
  responseType: 'CODE',
  scope: 'email openid',
};

const userPool = new CognitoUserPool(awsExports);

export default userPool;
