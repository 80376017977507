import React, { FC, useState } from 'react';

import styled from '@emotion/styled';
import { Key } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Modal, ModalProps, Typography, Button, TextField, Box, Link } from '@mui/material';

import { AvailProvider } from '../../config/cognitoConfig';
import { startMicrosoftLogin } from '../../services/aws.service';

// eslint-disable-next-line max-len
const CONTACT_MAIL = 'mailto:info@knowledge-in-a-box.de?cc=till.hoffmann@knowledge-in-a-box.de&subject=Error%20by%20creating%20new%20account&body=Dear%20Knowledge%20in%20a%20Box%20Team%2C%0D%0A%0D%0AI%20had%20difficulties%20registering.%0D%0A%0D%0AName%3A%0D%0AEmail%3A%0D%0ACompany%3A%0D%0A%0D%0AKind%20regards%2C%0D%0A';

const style = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '16px',
  width: 500,
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type Provider = {
  domain: string;
  tenant: AvailProvider;
};

const allowedProvider: Provider[] = [
  {
    domain: 'modell-aachen.de',
    tenant: AvailProvider.Qwiki,
  },
  {
    domain: 'knowledge-in-a-box.de',
    tenant: AvailProvider.KiaB,
  },
  {
    domain: 'pieninggmbh.de',
    tenant: AvailProvider.Piening,
  },
  {
    domain: 'eugster.ch',
    tenant: AvailProvider.Eugster,
  },
];

type Props = {
  children: React.ReactNode;
};

const SSOForm: FC<Props> = ({ children }) => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [showSSOUri, setShowSSOUri] = useState<string | undefined>(undefined);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const emailDomain = email?.split('@')[1];
    if (!emailDomain) {
      setError('Please enter a valid email address');
      setIsLoading(false);
      return;
    }
    const tenant = allowedProvider.find((provider) => emailDomain.includes(provider.domain));
    if (!tenant) {
      setError('Your company is not registered with us. Please contact support.');
      setIsLoading(false);
      return;
    }

    // show the sso uri after 4 seconds
    const ssoUri = startMicrosoftLogin(tenant.tenant);
    setTimeout(() => {
      setIsLoading(false);
      setShowSSOUri(ssoUri);
    }, 2000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(undefined);
  };

  return (
    <Box>
      <Box onClick={handleOpen}>
        { children }
      </Box>
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h4" component="h2">
            Enter your business email
          </Typography>

          <TextField
            margin="normal"
            fullWidth
            required
            label="Email Address"
            type="email"
            autoFocus
            onChange={handleChange}
          />

          { error && (
            <Box sx={{ my: 2, color: 'error.main' }}>
              { error }
              <Box sx={{ textAlign: 'center', paddingY: '2rem' }}>
                <Button
                  variant="text"
                  href={CONTACT_MAIL}
                >
                  Contact
                </Button>
              </Box>
            </Box>

          )}

          { showSSOUri && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" component="span">
                If you do not get redirected, click
                {' '}
              </Typography>
              <Link href={showSSOUri}>
                here
              </Link>
            </Box>
          )}

          <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
            <Button onClick={handleClose} sx={{ mr: 2 }} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={onSubmit}
              disabled={!!error}
              startIcon={<Key />}
            >
              Login
            </LoadingButton>
          </Box>
        </Box>
      </ModalStyle>
    </Box>
  );
};

export default SSOForm;
